<template>
  <div class="sld_quick_reply">
    <div class="middle_bar">
    <!--查询区-->
      <div class="chat_date">
        <span>{{L['日期']}}：</span>
        <el-date-picker v-model="dateRange" type="daterange" range-separator="-" :start-placeholder="L['开始日期']"
                        :end-placeholder="L['结束日期']" :disabled-date="disabledDate">
        </el-date-picker>
      </div>
      <div class="chat_date">
        <span>{{L['客服名称']}}：</span>
        <span v-if="selectValue.length == 0" class="placeholder">请选择客服名称</span>
        <el-select size="large" v-model="selectValue" multiple filterable allow-create clearable
                   default-first-option :reserve-keyword="false" placeholder=" ">
          <el-option
              v-for="item in filterList.list"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          />
        </el-select>
      </div>

      <button class="query" @click="query">{{L['查询']}}</button>
      <button class="query" @click="reset">{{L['重置']}}</button>
      <button class="query" @click="download">{{L['导出']}}</button>
      <!--查询区-->
    </div>
    <div class="top_header">
      <p class="header_title">{{L['客服数据']}}</p>
      <p><text>T+1计算数列：咨询人数、接待人数、咨询次数、接待次数、平均首次响应时间</text></p>
      <p><text>T+2计算数列：销售额、销售人数、销售占比、询单转化率</text></p>
    </div>
    <!--客服数据-->
    <el-table :data="keFuList.list" border>
      <el-table-column type="index" :label="L['序号']">
      </el-table-column>
      <el-table-column prop="statsTime" :label="L['日期']" >
      </el-table-column>
      <el-table-column prop="vendorName" :label="L['客服名称']" >
      </el-table-column>
      <el-table-column prop="isVendorContactCount" :label="L['主动发起']" >
      </el-table-column>
      <el-table-column prop="memberNum" :label="L['咨询人数']" >
      </el-table-column>
      <el-table-column prop="replyMemberNum" :label="L['接待人数']" >
      </el-table-column>
      <el-table-column prop="memberMsgNum" :label="L['咨询次数']" >
      </el-table-column>
      <el-table-column prop="vendorMsgNum" :label="L['接待次数']">
      </el-table-column>
      <el-table-column prop="firstDurationValue" :label="L['平均首次响应时间']" width="140">
      </el-table-column>
      <el-table-column prop="averageDurationValue" :label="L['平均响应时间']" width="110">
      </el-table-column>
      <el-table-column prop="orderAmountValue" :label="L['销售额']">
      </el-table-column>
      <el-table-column prop="orderMemberNumValue" :label="L['销售人数']" >
      </el-table-column>
      <el-table-column prop="orderPercentValue" :label="L['销售占比']" >
      </el-table-column>
      <el-table-column prop="orderConvertRateValue" :label="L['询单转化率']" >
      </el-table-column>
    </el-table>
    <div class="empty_quick" v-if="!keFuList.list.length>0&&firstLoading">
      <img src="@/assets/goods/empty_data.png" alt="">
      <p>{{L['暂无客服数据内容~']}}</p>
    </div>
    <!--客服数据end-->
    <div class="top_header">
      <p class="header_title">{{L['客服数据汇总']}}</p>
      <p><text>客服数据汇总：汇总客服数据列表总和</text></p>
    </div>
    <!--客服汇总数据-->
    <el-table :data="keFuTotalList.list" border>
      <el-table-column prop="memberNum" :label="L['咨询总人数']">
      </el-table-column>
      <el-table-column prop="replyMemberNum" :label="L['接待总人数']">
      </el-table-column>
      <el-table-column prop="isVendorContactCount" :label="L['主动发起']" >
      </el-table-column>
      <el-table-column prop="memberMsgNum" :label="L['咨询总次数']">
      </el-table-column>
      <el-table-column prop="vendorMsgNum" :label="L['接待总次数']">
      </el-table-column>
      <el-table-column prop="firstDurationValue" :label="L['平均首次响应时间']">
      </el-table-column>
      <el-table-column prop="averageDurationValue" :label="L['平均响应时间']">
      </el-table-column>
      <el-table-column prop="totalAmount" :label="L['销售总额']">
      </el-table-column>
      <el-table-column prop="orderMemberNum" :label="L['销售总人数']">
      </el-table-column>
      <el-table-column prop="orderConvertRateValue" :label="L['询单转化率']" >
      </el-table-column>
    </el-table>
    <!--客服汇总数据end-->
  </div>
</template>

<script>
import { ref, getCurrentInstance, reactive, onMounted, watch } from 'vue'
import { ElMessage } from 'element-plus'

export default {
  setup() {
    const keFuList = reactive({ list: [] })//客服信息列表
    const keFuTotalList = reactive({ list: [] })//客服汇总信息列表
    const params = reactive({ current: 1 })
    const { proxy } = getCurrentInstance()
    const firstLoading = ref(false)
    const L = proxy.$getCurLanguage()
    const filterList = reactive({list: [{ label:'', value:''}] }) // 筛选数据
    const selectValue = ref([]) // select绑定值
    const dateRange = ref([])//日期起止事件
    const startTime = ref('')//开始时间
    const endTime = ref('')//截止时间
    const disabledDate = (time) => {
      return time.getTime() > Date.now();
    }

    //查询客服数据
    const query = () => {
      if (!dateRange.value) {
        ElMessage({
          message: '请选择查询日期',
          type: 'warning',
          center:true
        });
        return false
      }
      let vendorIds = selectValue.value.toString()
      startTime.value = [new Date(dateRange.value[0]).getFullYear(), new Date(dateRange.value[0]).getMonth() + 1, new Date(dateRange.value[0]).getDate()].map(item => item.toString().length >= 2 ? item : '0' + item).join('-')
      endTime.value = [new Date(dateRange.value[1]).getFullYear(), new Date(dateRange.value[1]).getMonth() + 1, new Date(dateRange.value[1]).getDate()].map(item => item.toString().length >= 2 ? item : '0' + item).join('-')
      getKeFuList(startTime.value, endTime.value, vendorIds)
    }

    //重置搜索条件
    const reset = () => {
      // 默认时间为昨天早上到今天
      dateRange.value[0] = new Date() - (3600 * 1000 * 24) // 昨天
      dateRange.value[1] = new Date() // 今天
      selectValue.value = []
      delete params.vendorIds
      let vendorIds = selectValue.value.toString()
      startTime.value = [new Date(dateRange.value[0]).getFullYear(), new Date(dateRange.value[0]).getMonth() + 1, new Date(dateRange.value[0]).getDate()].map(item => item.toString().length >= 2 ? item : '0' + item).join('-')
      endTime.value = [new Date(dateRange.value[1]).getFullYear(), new Date(dateRange.value[1]).getMonth() + 1, new Date(dateRange.value[1]).getDate()].map(item => item.toString().length >= 2 ? item : '0' + item).join('-')
      getKeFuList(startTime.value, endTime.value, vendorIds)
    }

    const download=() =>{

      if (!dateRange.value) {
        ElMessage({
          message: '请选择查询日期',
          type: 'warning',
          center:true
        });
        return false
      }
      let vendorIds = selectValue.value.toString()
      startTime.value = [new Date(dateRange.value[0]).getFullYear(), new Date(dateRange.value[0]).getMonth() + 1, new Date(dateRange.value[0]).getDate()].map(item => item.toString().length >= 2 ? item : '0' + item).join('-')
      endTime.value = [new Date(dateRange.value[1]).getFullYear(), new Date(dateRange.value[1]).getMonth() + 1, new Date(dateRange.value[1]).getDate()].map(item => item.toString().length >= 2 ? item : '0' + item).join('-')

      if (startTime.value) {
        startTime.value = startTime.value + ' 00:00:00'
      }
      if (endTime.value) {
        endTime.value = endTime.value + ' 23:59:59'
      }

      let data ={
        statsStartTime:startTime.value,
        statsEndTime:endTime.value,
      }
      if(vendorIds){
        data.vendorIds = vendorIds
      }else {
        delete data.vendorIds
      }
      proxy.$download('v3/helpdesk/seller/stats/export', data).then(res => {
          let fileName='客服数据'
          let suffix='xlsx'

          let url = window.URL.createObjectURL(new Blob([res]))
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', fileName+"."+suffix)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)
      })
    }

    //获取客服数据
    const getKeFuList = (startTime,endTime,vendorIds) => {
      if (startTime) {
        params.statsStartTime = startTime + ' 00:00:00'
      }
      if (endTime) {
        params.statsEndTime = endTime + ' 23:59:59'
      }
      if (vendorIds) {
        params.vendorIds = vendorIds
      }else {
        delete params.vendorIds
      }
      proxy.$get('v3/helpdesk/seller/stats/list', params).then(res => {
        if (res.state == 200) {
          if (res.data && res.data.statsData != []) {
            keFuList.list = res.data.statsData
            keFuTotalList.list.shift()
            keFuTotalList.list.push(res.data.totalData)
            firstLoading.value = true
          }
        }
      })
    }

    // 获取商户信息
    const getSellerList = () => {
      let param ={}
      param.pageSize = 999
      proxy.$get('v3/seller/seller/vendor/list', param).then(res => {
        if (res.state == 200) {
          res.data.list.forEach((item,index)=>{
            filterList.list.push({text:'',value:''})
            filterList.list[index].label = item.vendorName
            filterList.list[index].value = item.vendorId
          })
          filterList.list.pop()

        }
      })
    }

    onMounted(() => {
      getSellerList()
      // 默认查询时间是昨天到今天
      dateRange.value[0] = new Date() - (3600 * 1000 * 24) //昨天
      dateRange.value[1] = new Date() // 今天
      startTime.value = [new Date(dateRange.value[0]).getFullYear(), new Date(dateRange.value[0]).getMonth() + 1, new Date(dateRange.value[0]).getDate()].map(item => item.toString().length >= 2 ? item : '0' + item).join('-')
      endTime.value = [new Date(dateRange.value[1]).getFullYear(), new Date(dateRange.value[1]).getMonth() + 1, new Date(dateRange.value[1]).getDate()].map(item => item.toString().length >= 2 ? item : '0' + item).join('-')
      getKeFuList(startTime.value, endTime.value, '')
    })

    watch(() => dateRange.value,(nv) =>{
      if (nv == null) {
        dateRange.value = ''
        delete params.statsStartTime
        delete params.statsEndTime
      }
    })


    return {
      keFuList,
      keFuTotalList,
      firstLoading,
      L,
      getSellerList,
      filterList,
      selectValue,
      query,
      dateRange,
      endTime,
      startTime,
      disabledDate,
      reset,
      download,
    }
  }
}
</script>

<style lang="scss">
.el-table th>.cell {
  text-align: center;
}

.el-table .cell {
  display: flex;
  justify-content: center;
}

.el-table__empty-block {
  display: none;
}

button {
  padding: 4px 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 13px;
  margin-left: 10px;
  border-radius: 4px;
  background: #FFF;
  border: 1px solid #DCDFE6;
  color: #606266;

  &:hover {
    border: 1px solid $colorMain;
    color: $colorMain;
  }
}



.sld_quick_reply {
  .top_header {
    width: 100%;
    height: 59px;
    border-bottom: 1px solid #F6F6F6;
    margin-top: 20px;
    flex-shrink: 0;
    text{
      color: $colorMain;
    }

    .header_title {
      padding-left: 10px;
      border-left: 4px solid $colorMain;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      margin-bottom: 10px;
    }

    button {
      color: #fff;
      padding: 11px;
      background-color: $colorMain;
      border-radius: 3px;
      border: none;
      margin-right: 15px;
      font-size: 14px;
      font-family: Microsoft YaHei;
    }
  }

  .top_bar {
    padding: 13px 0;
    background-color: #eee;
    display: flex;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;

    .number_title {
      width: 210px;
      text-align: center;
      /* padding: 0 90px; */
    }

    .reply_title {
      width: 455px;
      /* padding: 0 190px; */
      text-align: center;
    }

    .sort_title {
      width: 310px;
      /* padding: 0 140px; */
      text-align: center;
    }

    .default_use_title {
      width: 338px;
      text-align: center;
      /* padding: 0 154px; */
    }

    .operate_title {
      width: 310px;
      /* padding: 0 140px; */
      text-align: center;
    }
  }

  .container {
    height: 710px;

    .con_item {
      width: 99%;
      display: flex;
      height: 50px;
      align-items: center;
      border-bottom: 1px solid #f2f2f2;

      .number {
        width: 210px;
        text-align: center;
      }

      .sort {
        text-align: center;
        width: 310px;
      }

      .default_use {
        padding: 0 150px;
      }

      .operate {
        width: 310px;
        display: flex;
        justify-content: center;

        button {
          padding: 4px 15px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 13px;
          margin-left: 10px;
          border-radius: 4px;
          background: #FFF;
          border: 1px solid #DCDFE6;
          color: #606266;

          &:hover {
            border: 1px solid $colorMain;
            color: $colorMain;
          }
        }
      }

      .content {
        width: 455px;
        text-align: center;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        padding-left: 10px;
        padding-right: 10px;
      }

    }
  }

  .sld_pagination {
    margin-top: 20px;
  }

  .el-dialog {
    border-radius: 10px;
  }

  .el-dialog__header {
    background: #409Eff;
    border-top-right-radius: 9px;
    border-top-left-radius: 9px;
    height: 40px;
    padding: 11px 20px 10px;
  }

  .el-dialog__headerbtn {
    top: 13px;
  }

  .el-dialog__title {
    color: #fff;
  }

  .el-dialog__headerbtn .el-dialog__close {
    color: #fff;
  }

  .el-button {
    padding: 7px 19px;
  }

  #popTable {
    margin: 0 auto;
    border-spacing: 10px;

    .title {
      text-align: right;
    }

    .tip {
      color: #C0C4CC;
    }

    i {
      color: #e2231a;
      font-style: normal;
    }

    .message {
      font-size: 12px;
      line-height: 2;
      padding-top: 4px;
      color: #e2231a;
      font-family: Microsoft YaHei;
    }

    td {
      max-width: 284px;

      &.title {
        padding-top: 9px;
      }
    }
  }
}

.empty_quick {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 70px 0;

  p {
    color: #999999;
    font-size: 14px;
    margin-left: 10px;
  }
}
.middle_bar {
  width: 100%;
  height: 69px;
  display: flex;
  align-items: center;

  .chat_date {
    display: flex;
    align-items: center;
    position: relative;
    margin-left: 20px;
  }

  .query {
    width: 74px;
    height: 30px;
    background: #0E6FD7;
    cursor: pointer;
    border-radius: 3px;
    border: none;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    margin-left: 50px;
  }
  .placeholder {
    position: absolute;
    right: 95px;
    z-index: 999;
    color: #b7b9c0;
    font-size: 14px;
  }
}
</style>